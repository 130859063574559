<script setup lang="ts"></script>

<template>
  <div>
    layouts-default-header
    <slot />
    layouts-default-footer
  </div>
</template>

<style scoped></style>
